var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "div",
      {
        staticClass: "thank-you-box",
      },
      [
        _c(
          "div",
          {
            staticClass: "thank-container",
          },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass: "text-center",
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.wuwowThankyouPng,
                        alt: "WUWOW 線上英語教學 感謝您",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "b-row",
              {
                staticClass: "mt-5",
              },
              [
                _c("b-col", [
                  _c(
                    "span",
                    {
                      staticClass: "thank-you-content",
                    },
                    [
                      _vm._v("謝謝您提供的訊息，我們已經收到您的資料！"),
                      _c("br"),
                      _vm._v("我們會盡快安排專員與您聯絡！"),
                    ]
                  ),
                ]),
              ],
              1
            ),
            _c(
              "b-row",
              {
                staticClass: "mt-5",
              },
              [
                _c(
                  "b-col",
                  {
                    staticClass: "thank-button-group",
                  },
                  [
                    _vm.hasWritedPhone
                      ? _c(
                          "b-button",
                          {
                            staticClass:
                              "go-back-home-btn m-bottom-5 btn-hover",
                            attrs: {
                              variant: "primary",
                              href: "https://lihi2.com/EeySZ",
                              target: "_blank",
                            },
                          },
                          [_vm._v("領取 敦煌專屬兒美單字書 (附音檔)")]
                        )
                      : _vm._e(),
                    _c(
                      "b-button",
                      {
                        staticClass: "go-back-home-btn",
                        attrs: {
                          variant: "outline-primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.backToHome()
                          },
                        },
                      },
                      [_vm._v("返回WUWOW Jr官網")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }